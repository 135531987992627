<mat-card class="header">
  <p class="bg-pangea titulo" style="display: felx; flex-grow: 1">
    Bienvenido Usuario(a) {{ getName() }}!
  </p>
  <select
    (change)="onChangeVno($event)"
    style="align-self: center; padding: 0.5em"
  >
    <option *ngFor="let vno of getVnos()" [ngValue]="vno.id">
      {{ vno.label }}
    </option>
  </select>
</mat-card>

<mat-drawer-container
  class="example-container"
  style="width: 90%; margin: 0px auto"
>
  <!-- *ngIf="usuarioEstado" -->
  <mat-drawer mode="side" style="width: 300px" fxLayoutAlign="center" opened>
    <mat-selection-list #menus [multiple]="false">
      <mat-list-option
        [class.selected]="menu == selectedMenu"
        *ngFor="let menu of menuReporte; let i = index"
        [value]="menu"
        [selected]="i == 0 ? true : false"
        (click)="onSelected(menu)"
        fxLayout="row wrap"
      >
        <div fxLayout="row" style="width: 250px">
          <div fxFlex="10" style="margin-top: 1px" fxFlexOffset="20px">
            <svg height="29" width="29" xmlns="http://www.w3.org/2000/svg">
              <path
                class="icono"
                d="M1.33333 8.83333H6.33333C6.79167 8.83333 7.16667 8.45833 7.16667 8V1.33333C7.16667 0.875 6.79167 0.5 6.33333 0.5H1.33333C0.875 0.5 0.5 0.875 0.5 1.33333V8C0.5 8.45833 0.875 8.83333 1.33333 8.83333ZM1.33333 15.5H6.33333C6.79167 15.5 7.16667 15.125 7.16667 14.6667V11.3333C7.16667 10.875 6.79167 10.5 6.33333 10.5H1.33333C0.875 10.5 0.5 10.875 0.5 11.3333V14.6667C0.5 15.125 0.875 15.5 1.33333 15.5ZM9.66667 15.5H14.6667C15.125 15.5 15.5 15.125 15.5 14.6667V8C15.5 7.54167 15.125 7.16667 14.6667 7.16667H9.66667C9.20833 7.16667 8.83333 7.54167 8.83333 8V14.6667C8.83333 15.125 9.20833 15.5 9.66667 15.5ZM8.83333 1.33333V4.66667C8.83333 5.125 9.20833 5.5 9.66667 5.5H14.6667C15.125 5.5 15.5 5.125 15.5 4.66667V1.33333C15.5 0.875 15.125 0.5 14.6667 0.5H9.66667C9.20833 0.5 8.83333 0.875 8.83333 1.33333Z"
                fill="#667080"
              />
            </svg>
          </div>
          <div fxFlex="90">
            <p>{{ menu }}</p>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </mat-drawer>
  <mat-drawer-content class="bg-pangea" style="background-color: white">
    <div
      *ngIf="selectedMenu == 'Mantenimiento Zona'"
      style="height: 100%; width: 100%"
    >
      <!--setUsersForm()-->
      <form
        [formGroup]="tableFormZona"
        fxLayout="row wrap"
        (ngSubmit)="enviarZona()"
      >
        <h2 class="bg-pangea" fxFlex="100" class="title">
          Configuración por Zona
        </h2>
        <div class="wrapper">
          <table
            [style.opacity]="estadoGuardar == true ? 0.7 : 1"
            mat-table
            [dataSource]="dataSourceZona"
            formArrayName="reportsZona"
            class="mat-elevation-z8"
            fxFlex="100"
          >
            <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            <ng-container matColumnDef="rowKey">
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
                [hidden]="true"
              >
                <input type="hidden" formControlName="rowKey" />
              </td>
            </ng-container>

            <!-- precio Column -->
            <ng-container matColumnDef="precio">
              <th mat-header-cell *matHeaderCellDef>Precio Mensual UIC</th>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
              >
                <input
                  type="text"
                  size="20"
                  class="campo"
                  formControlName="precio"
                  (keypress)="onEventKeyPress($event)"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="campoNoValidoZona('precio', rowIndex)"
                  >es requerido</span
                >
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="mesInicial">
              <th mat-header-cell *matHeaderCellDef>Mes Inicial</th>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
              >
                <!--size="20"-->
                <input
                  mask="separator.2"
                  thousandSeparator=""
                  [prefix]="prefix"
                  maxlength="21"
                  type="text"
                  class="campo"
                  formControlName="mesInicial"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="campoNoValidoZona('mesInicial', rowIndex)"
                  >es requerido</span
                >
              </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="mesFinal">
              <th mat-header-cell *matHeaderCellDef>Mes Final</th>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
              >
                <input
                  type="text"
                  size="20"
                  class="campo"
                  formControlName="mesFinal"
                  (keypress)="onEventKeyPress($event)"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="campoNoValidoZona('mesFinal', rowIndex)"
                  >es requerido</span
                >
              </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="minLima">
              <th mat-header-cell *matHeaderCellDef>Take Up Min Lima (%)</th>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
              >
                <!--size="20"-->
                <input
                  mask="separator.2"
                  thousandSeparator=""
                  [suffix]="suffix"
                  maxlength="21"
                  type="text"
                  class="campo"
                  formControlName="minLima"
                  (keypress)="onEventKeyPress($event)"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="campoNoValidoZona('minLima', rowIndex)"
                  >es requerido</span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="minProvincia">
              <th mat-header-cell *matHeaderCellDef>Take Up Min Prov (%)</th>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
              >
                <!--size="20"-->
                <input
                  mask="separator.2"
                  thousandSeparator=""
                  [suffix]="suffix"
                  maxlength="21"
                  type="text"
                  class="campo"
                  formControlName="minProvincia"
                  (keypress)="onEventKeyPress($event)"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="campoNoValidoZona('minProvincia', rowIndex)"
                  >es requerido</span
                >
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <div
          fxFlex="100"
          fxLayout="row"
          fxLayoutAlign="end start"
          class="footer"
          fxLayoutGap="20px"
        >
          <div fxFlex="0 1 auto">
            <button
              class="btn btn-primary float-end"
              (click)="editarTablaZona()"
              type="button"
            >
              Editar
            </button>
          </div>
          <div fxFlex="0 1 auto">
            <button
              class="btn btn-primary float-end"
              [disabled]="tableFormZona.invalid || estadoGuardar"
              type="submit"
            >
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>

    <div
      *ngIf="selectedMenu == 'Mantenimiento Región'"
      style="height: 100%; width: 100%"
    >
      <!--setUsersForm()-->
      <form
        [formGroup]="tableForm"
        fxLayout="row wrap"
        (ngSubmit)="enviarRegion()"
      >
        <h2 class="bg-pangea" fxFlex="100" class="title">
          Configuración por Región
        </h2>
        <div class="wrapper">
          <table
            [style.opacity]="estadoGuardar == true ? 0.7 : 1"
            mat-table
            [dataSource]="dataSource"
            formArrayName="reports"
            class="mat-elevation-z8"
            fxFlex="100"
          >
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
            <ng-container matColumnDef="rowKey">
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
                [hidden]="true"
              >
                <input type="hidden" formControlName="rowKey" />
              </td>
            </ng-container>

            <!-- precio Column -->
            <ng-container matColumnDef="precio">
              <th mat-header-cell *matHeaderCellDef>Precio Mensual UIC</th>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
              >
                <input
                  type="text"
                  size="20"
                  class="campo"
                  formControlName="precio"
                  (keypress)="onEventKeyPress($event)"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="campoNoValido('precio', rowIndex)"
                  >es requerido</span
                >
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="mesInicial">
              <th mat-header-cell *matHeaderCellDef>Mes Inicial</th>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
              >
                <!--size="20"-->
                <input
                  mask="separator.2"
                  thousandSeparator=""
                  [prefix]="prefix"
                  type="text"
                  maxlength="21"
                  class="campo"
                  formControlName="mesInicial"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="campoNoValido('mesInicial', rowIndex)"
                  >es requerido</span
                >
              </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="mesFinal">
              <th mat-header-cell *matHeaderCellDef>Mes Final</th>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
              >
                <input
                  type="text"
                  size="20"
                  class="campo"
                  formControlName="mesFinal"
                  (keypress)="onEventKeyPress($event)"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="campoNoValido('mesFinal', rowIndex)"
                  >es requerido</span
                >
              </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="minLima">
              <th mat-header-cell *matHeaderCellDef>Take Up Min Lima (%)</th>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
              >
                <!--size="20"-->
                <input
                  mask="separator.2"
                  thousandSeparator=""
                  [suffix]="suffix"
                  maxlength="21"
                  type="text"
                  class="campo"
                  formControlName="minLima"
                  (keypress)="onEventKeyPress($event)"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="campoNoValido('minLima', rowIndex)"
                  >es requerido</span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="minProvincia">
              <th mat-header-cell *matHeaderCellDef>Take Up Min Prov (%)</th>
              <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
                [formGroupName]="rowIndex"
              >
                <!--size="20"-->
                <input
                  mask="separator.2"
                  thousandSeparator=""
                  [suffix]="suffix"
                  type="text"
                  maxlength="21"
                  class="campo"
                  formControlName="minProvincia"
                  (keypress)="onEventKeyPress($event)"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="campoNoValido('minProvincia', rowIndex)"
                  >es requerido</span
                >
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>

        <div
          fxFlex="100"
          fxLayout="row"
          fxLayoutAlign="end start"
          class="footer"
          fxLayoutGap="20px"
        >
          <div fxFlex="0 1 auto">
            <button
              class="btn btn-primary float-end"
              (click)="editarTabla()"
              type="button"
            >
              Editar
            </button>
          </div>
          <div fxFlex="0 1 auto">
            <button
              class="btn btn-primary float-end"
              [disabled]="tableForm.invalid || estadoGuardar"
              type="submit"
            >
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>

    <mat-card
      style="height: 100%; width: 100%"
      *ngIf="selectedMenu == 'Carga de archivo'"
    >
      <h2 class="bg-pangea">Carga de archivo</h2>
      <input
        class="form-control"
        type="file"
        (change)="onFileChange($event)"
        multiple="false"
        #inputFile
      />

      <div
        *ngIf="estadoProcesar"
        fxLayout="row"
        fxLayoutAlign="start start"
        style="margin: 20px auto"
        fxLayoutGap="20px"
      >
        <div fxFlex="0 1 auto" class="fileupload">
          <p><mat-icon>check_circle</mat-icon>Archivo procesó correctamente</p>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        style="margin: 20px auto"
        fxLayoutGap="20px"
      >
        <div fxFlex="0 1 auto">
          <button
            class="btn btn-primary float-end"
            (click)="procesar()"
            type="button"
            [disabled]="habilitarBotonProcesar"
          >
            Procesar
          </button>
        </div>
      </div>
    </mat-card>

    <!-- UIC UIP-->
    <mat-card
    style="height: 100%; width: 100%"
    *ngIf="selectedMenu == 'Carga de UIP/UIC'"
  >
  <div class="row">
    <!-- UIP -->
    <div class="col-md">
      <h2 class="bg-pangea">Carga de UIP</h2>
      <input
        class="form-control"
        type="file"
        (change)="onUIPCFileChange($event, 'UIP')"
        multiple="false"
        accept="text/csv"
        #inputUIPFile
      />
      <div *ngIf="uploadData['UIP'].state == 'loading'">
        <span>Cargando archivo ... </span>
        <mat-progress-bar mode="determinate" [value]="uploadData['UIP'].progress"></mat-progress-bar>
      </div>

      <div
        *ngIf="uploadData['UIP'].state == 'done'"
        fxLayout="row"
        fxLayoutAlign="start start"
        style="margin: 20px auto"
        fxLayoutGap="20px"
      >
        <div fxFlex="0 1 auto" class="fileupload">
          <p><mat-icon>check_circle</mat-icon>Archivo procesó correctamente</p>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        style="margin: 20px auto"
        fxLayoutGap="20px"
      >
        <div fxFlex="0 1 auto">
          <button
            class="btn btn-primary float-end"
            (click)="uploadFileAzure('UIP')"
            type="button"
            [disabled]="!uploadData['UIP'].valid || uploadData['UIP'].state === 'loading'"
          >
            Subir UIP
          </button>
        </div>
      </div>
    </div>
    <!-- END UIP -->
    <!-- UIC -->
    <div class="col-md">
      <h2 class="bg-pangea">Carga de UIC</h2>
      <input
        class="form-control"
        type="file"
        (change)="onUIPCFileChange($event, 'UIC')"
        multiple="false"
        accept="text/csv"
        #inputUICFile
      />
      <div *ngIf="uploadData['UIC'].state == 'loading'">
        <span>Cargando archivo ... </span>
        <mat-progress-bar mode="determinate" [value]="uploadData['UIC'].progress"></mat-progress-bar>
      </div>

      <div
        *ngIf="uploadData['UIC'].state == 'done'"
        fxLayout="row"
        fxLayoutAlign="start start"
        style="margin: 20px auto"
        fxLayoutGap="20px"
      >
        <div fxFlex="0 1 auto" class="fileupload">
          <p><mat-icon>check_circle</mat-icon>Archivo procesó correctamente</p>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        style="margin: 20px auto"
        fxLayoutGap="20px"
      >
        <div fxFlex="0 1 auto">
          <button
            class="btn btn-primary float-end"
            (click)="uploadFileAzure('UIC')"
            type="button"
            [disabled]="!uploadData['UIC'].valid || uploadData['UIC'].state === 'loading'"
          >
            Subir UIC
          </button>
        </div>
      </div>
    </div>
    <!-- END UIP -->
  </div>
  </mat-card>
  <!-- END UIP UIC -->
  </mat-drawer-content>
</mat-drawer-container>
