import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { TokenInterceptor } from './services/token.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeModule } from './modules/home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ReportsModule } from './modules/reports/reports.module';
import {
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_INSTANCE,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

//const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      authority: environment.urlBase.authority,
      clientId: environment.urlBase.clientId,
      //AMBIENTE LOCAL
      redirectUri: environment.urlBase.redirectUri,
      //AMBIENTE DESARROLLO/CERTIFICACION/PRODUCCION
      //redirectUri: environment.urlBase.redirectUri + window.location.pathname,
      //redirectUri: 'https://staceu2bssfrntd01.z20.web.core.windows.net/',
      // postLogoutRedirectUri: "http://localhost:4200/home/login",
      navigateToLoginRequestUrl: true,
    },
  });
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    HomeModule,
    ReportsModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    MsalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
