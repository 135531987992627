import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tokenData: any = Object.keys(sessionStorage)
      .map((e: any) => {
        const data: any = sessionStorage.getItem(e);
        let json: any = data;
        try {
          json = JSON.parse(data);
        } catch (error) {}
        return json;
      })
      .find((s) => s?.credentialType === 'IdToken');

    const userToken = tokenData?.secret;
    const modifiedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    return next.handle(modifiedReq);
  }
}
