<mat-toolbar fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign.xs="center"
  style="background-color: #fff;box-shadow: rgba(3, 27, 78, 0.1) 0px 2px 4px;">
  

  <div fxFlex="1 0 auto" fxFlex.xs="2 0 100" style="border-right: 2px solid #BEC5D0;text-align: center;"
    fxFlexOrder.xs="1">
    <span class="bg-pangea">Reportes Facturación</span>
  </div>
  <div fxFlex="8 0 auto" fxFlex.xs="4 0 50" fxFlexOffset="30px" fxFlexOffset.xs="0" fxFlexOrder.xs="3">
    <img style="margin-bottom: 7px;margin-right: 10px;" [src]="'assets/iconos/pangea.png'" alt="Logo PANGEA"
      width="29" height="29">
    <span class="bg-pangea">PANGEA</span>
  </div>
  <div fxFlex="1 0 auto" fxFlex.xs="1 0 50" fxFlexOrder.xs="2" *ngIf="!isLoggedIn()">
    <button  mat-raised-button style="background-color: #4c7de7;color:#fff" type="button" (click)="login()">
      SIGN IN
    </button>
  
  </div>
  <div fxFlex="1 0 auto" fxFlex.xs="1 0 50" fxFlexOrder.xs="2" *ngIf="isLoggedIn()">
    <button  mat-raised-button style="background-color: #4c7de7;color:#fff" type="button" (click)="logout()">
      SIGN OUT
    </button>
  
  </div>

  
</mat-toolbar>
<br>
<!-- <div *ngIf="isLoggedIn()">
  <h3>You are logged in</h3>
</div>

<div *ngIf="!isLoggedIn()">
  <h3>You are NOT logged in</h3>
</div>
<p>Bienvenido {{getName()}}!</p> -->
<router-outlet></router-outlet>
