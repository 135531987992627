// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlBase: {
    authority:
      'https://login.microsoftonline.com/3cffedfc-da13-4607-a160-06a25211238a',
    //AMBIENTE CERTIFICACION
    mantenimientoRegion: "https://api.cer.pangeaco.pe/fnapeu2bssc01/",
    mantenimientoZona: "https://api.cer.pangeaco.pe/fnapeu2bssc02/",
    maintenanceVno: 'https://api.cer.pangeaco.pe/bss/takerate/vno/',
    cargaArchivo: "https://api.cer.pangeaco.pe/fnapeu2bssc04/",
    subirUICPToken: 'https://fnapeu2bssc07.azurewebsites.net/api/generatesastoken',
    subirUICPBlob: 'https://staceu2etlbackc04.blob.core.windows.net',
    clientId: '40aa9534-ee27-4d9c-aed1-fdade3d21f1e',
    redirectUri: 'https://billing.cer.pangeaco.pe'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
