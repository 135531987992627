import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VnoService {
  pathGetAll: string;
  ruta: string;
  constructor(private http: HttpClient) {
    this.ruta = `${environment.urlBase.maintenanceVno}`;
    this.pathGetAll = `${this.ruta}getVno`;
  }

  getAll() {
    return this.http.get<any>(this.pathGetAll);
  }
}
