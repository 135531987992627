import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';


const oAuthConfig: AuthConfig = {
  issuer: 'https://accounts.google.com',
  strictDiscoveryDocumentValidation: false,
  redirectUri: window.location.origin,
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public estadUsuario: boolean = localStorage.getItem('token1')?true:false;
  constructor() { 
    //private readonly oAuthService: OAuthService
    // oAuthService.configure(oAuthConfig);
    // oAuthService.loadDiscoveryDocument().then(()=>{
    //   oAuthService.tryLoginImplicitFlow().then(()=>{
    //     if(!oAuthService.hasValidAccessToken()){
    //       oAuthService.initLoginFlow()
    //     }else{
    //       oAuthService.loadUserProfile().then((_)=>{
    //         console.log(JSON.stringify(_));
    //       })
    //     }
    //   })
    // })
  }
}
