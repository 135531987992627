import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/auth/pages/login/login.component';
import { HomeMainComponent } from './modules/home/pages/home-main/home-main.component';
import { ReportsMainComponent } from './modules/reports/pages/reports-main/reports-main.component';
import { AuthGuard } from './guard/auth.guard';
const routes: Routes = [


    {
      path: 'home',
      component: HomeMainComponent,
      canActivateChild:[AuthGuard],
      children:[
        {path: 'reports',
        component: ReportsMainComponent,},
        {path: 'login',
        component: LoginComponent}
      ]
    },

  
   { path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
