import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ZonaService {
  pathObtenerTodo: string;
  pathAgregar: string;
  pathActualizar: string;
  pathEliminar: string;
  ruta: string;
  constructor(private http: HttpClient) {
    this.ruta = `${environment.urlBase.mantenimientoZona}`;
    this.pathObtenerTodo = `${this.ruta}getSector`;
    this.pathAgregar = `${this.ruta}addSector`;
    this.pathActualizar = `${this.ruta}updateSector`;
    this.pathEliminar = `${this.ruta}deleteSector`;
  }

  obtenerTodo(vno: string) {
    return this.http.get<any>(this.pathObtenerTodo + `?vno=${vno}`);
  }

  agregar(regiones: any) {
    return this.http.post<any>(this.pathAgregar, regiones);
  }

  actualizar(region: any) {
    return this.http.put<any>(this.pathActualizar, region);
  }

  eliminar(id: number) {
    const ruta = `${this.pathEliminar}/${id}`;
    return this.http.delete<any>(ruta);
  }
}
