import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/services/auth.service';
import { CargaArchivoService } from 'src/app/services/carga-archivo.service';
import { RegionService } from 'src/app/services/region.service';
import { ZonaService } from 'src/app/services/zona.service';
import { VnoService } from 'src/app/services/vno.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BlobServiceClient } from '@azure/storage-blob';
import { environment } from 'src/environments/environment';
type AOA = any[][];

@Component({
  selector: 'app-reports-main',
  templateUrl: './reports-main.component.html',
  styleUrls: ['./reports-main.component.scss'],
})
export class ReportsMainComponent implements OnInit {
  menuReporte: string[] = [
    'Mantenimiento Zona',
    'Mantenimiento Región',
    'Carga de archivo',
    'Carga de UIP/UIC'
  ];
  pin = ["","","","",""];
  displayedColumns: string[] = [
    'precio',
    'mesInicial',
    'mesFinal',
    'minLima',
    'minProvincia',
  ];
  selectedMenu: any = 'Mantenimiento Zona';
  estadoGuardar: boolean = true;
  usuarioEstado: boolean = false;

  isIframe = false;
  loginDisplay = false;

  dataSourceZona: any;
  dataSource: any;
  tableForm: FormGroup = this.formBuilder.group({
    reports: this.formBuilder.array([]),
  });
  tableFormZona: FormGroup = this.formBuilder.group({
    reportsZona: this.formBuilder.array([]),
  });
  get usersArr() {
    return this.tableForm.get('reports') as FormArray;
  }

  get usersArrZona() {
    return this.tableFormZona.get('reportsZona') as FormArray;
  }

  //validacionMesInicial: string = '([>])([0-9]+)'
  validacionMesInicial: string = '([0-9]+)';
  validacionNumber: string = '([0-9]+)';
  estadoProcesar: boolean = false;

  vnos: any;
  selectedVno: any;
  data: AOA = [
    [1, 2],
    [3, 4],
  ];
  datoExcel: any = [];
  public suffix: string = '%';
  public prefix: string = '>';
  arrayZona: any[] = [];
  arrayRegion: any[] = [];
  habilitarBotonProcesar: boolean = true;
  @ViewChild('inputFile') fileCargaArchivo: ElementRef | any;
  // UIP UIC properties
  @ViewChild('inputUIPFile') inputUIPFile: ElementRef | any;
  @ViewChild('inputUICFile') inputUICFile: ElementRef | any;
  // estadoProcesarUIP: 'none' | 'loading' | 'success' | 'error' = 'none';
  // estadoProcesarUIC: 'none' | 'loading' | 'success' | 'error' = 'none';
  // habilitarBotonProcesarUIP = false;
  // habilitarBotonProcesarUIC = false;
  uploadData = {
    "UIC": {
      state: 'none',
      valid: false,
      progress: 0,
    },
    "UIP": {
      state: 'none',
      valid: false,
      progress: 0,
    },

  }

  constructor(
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private authService2: MsalService,
    private regionService: RegionService,
    private zonaService: ZonaService,
    private vnoService: VnoService,
    private cargaArchivoService: CargaArchivoService,
    private uploadFileService: UploadFileService,
    private http: HttpClient,
  ) {
    this.usuarioEstado = this.authservice.estadUsuario;
    this.dataSource = [];
    this.dataSourceZona = [];
    this.vnos = [];
    this.selectedVno = null;
    /*this.dataSource = [
      { precio: 22, mesInicial: '>0', mesFinal: 6, minLima: 0, minProvincia: 0 },
      { precio: 22, mesInicial: '>6', mesFinal: 12, minLima: 10, minProvincia: 10 },
      { precio: 22, mesInicial: '>12', mesFinal: 18, minLima: 13, minProvincia: 12 },
      { precio: 22, mesInicial: '>18', mesFinal: 24, minLima: 16, minProvincia: 15 },
      { precio: 22, mesInicial: '>24', mesFinal: 0, minLima: 18, minProvincia: 17 },
    ];*/
  }

  ngOnInit() {
    this.usuarioEstado = this.authservice.estadUsuario;
    this.loadVnos();
  }

  clearTableZona() {
    this.tableFormZona = this.formBuilder.group({
      reportsZona: this.formBuilder.array([]),
    });
  }

  setUsersFormZona() {
    this.clearTableZona();
    const userCtrl = this.tableFormZona.get('reportsZona') as FormArray;

    this.dataSourceZona.data.forEach((user: any) => {
      userCtrl.push(this.setUsersFormArray(user));
    });
  }
  clearTableRegion() {
    this.tableForm = this.formBuilder.group({
      reports: this.formBuilder.array([]),
    });
  }
  setUsersForm() {
    this.clearTableRegion();
    const userCtrl = this.tableForm.get('reports') as FormArray;

    this.dataSource.forEach((user: any) => {
      userCtrl.push(this.setUsersFormArray(user));
    });
  }

  editarTabla() {
    this.tableForm.enable();
    this.estadoGuardar = false;
  }

  editarTablaZona() {
    this.tableFormZona.enable();
    this.estadoGuardar = false;
  }

  onSelected(menu: any) {
    this.estadoGuardar = true;
    if (menu == 'Mantenimiento Zona') this.tableFormZona.disable();
    else this.tableForm.disable();
    this.selectedMenu = menu;
    this.clearFileInput('UIP');
    this.clearFileInput('UIC');
  }

  setUsersFormArray(user: any) {
    return this.formBuilder.group({
      rowKey: user.rowKey,
      precio: [user.precio, [Validators.pattern(this.validacionNumber)]],
      mesInicial: [
        user.mesInicial,
        [Validators.pattern(this.validacionMesInicial)],
      ],
      mesFinal: [user.mesFinal, [Validators.pattern(this.validacionNumber)]],
      minLima: [user.minLima, [Validators.pattern(this.validacionNumber)]],
      minProvincia: [
        user.minProvincia,
        [Validators.pattern(this.validacionNumber)],
      ],
    });
  }

  campoNoValido(campo: string, i: number) {
    //console.log('HOLA',this.usersArr.controls[i].get(campo)?.value);
    return (
      this.usersArr.controls[i].get(campo)?.invalid &&
      this.usersArr.controls[i].get(campo)?.touched
    );
  }

  campoNoValidoZona(campo: string, i: number) {
    return (
      this.usersArrZona.controls[i].get(campo)?.invalid &&
      this.usersArrZona.controls[i].get(campo)?.touched
    );
  }

  onEventKeyPress(event: any) {
    const charCode = event.which ? event.which : event.key;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  getVnos(): any {
    return this.vnos;
  }

  getName(): string | any {
    if (this.authService2.instance.getActiveAccount() == null) {
      return 'unknown';
    }

    return this.authService2.instance.getActiveAccount()?.name;
  }

  procesar() {
    this.estadoProcesar = true;
    this.mostrarLoading();
    this.cargaArchivoService
      .agregar(this.datoExcel, this.selectedVno.id)
      .subscribe(
        (resp) => {
          this.habilitarBotonProcesar = true;
          this.fileCargaArchivo.nativeElement.value = '';
          this.cerrarLoading();
          this.mensajeConfirmacion();
        },
        (error) => {
          this.cerrarLoading();
          this.mensajeError();
        }
      );
  }

  onFileChange(evt: any) {
    this.datoExcel = [];
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    //if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      let numeroFila = 1000;
      /* save data */
      this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.data.forEach((item, index) => {
        if (index != 0) {
          let obj = {
            PartitionKey: 'Price',
            RowKey: numeroFila + index,
            BillingAreaCode: item[0],
            BillingAreaName: item[1],
            BillingCode: item[8],
            Concept: item[4],
            Price: (Math.round((item[7] + Number.EPSILON) * 100) / 100).toFixed(
              2
            ),
            PriceCode: item[5],
            PriceType: item[6],
            ProductCode: item[2],
            ProductName: item[3],
            VNO_ID: this.selectedVno.id,
          };
          this.datoExcel.push(obj);
        }
      });
      if (this.datoExcel.length > 0) {
        this.habilitarBotonProcesar = false;
        this.estadoProcesar = false;
      }
    };
    if (target.files.length > 0) reader.readAsBinaryString(target.files[0]);
    else this.habilitarBotonProcesar = true;
  }

  onChangeVno(e: any) {
    this.selectedVno = this.vnos[e.target.selectedIndex];
    this.getNextData();
  }

  loadVnos() {
    this.vnoService.getAll().subscribe((resp) => {
      this.vnos = resp.map((vn: any) => ({
        id: vn.VNO_ID,
        label: `${vn.VNO_ID} (${vn.Description})`,
      }));
      this.selectedVno = this.vnos[0];
      this.getNextData();
    });
  }

  getNextData() {
    this.cargarZona();
    this.cargarRegion();
    //this.setUsersForm();
    //this.tableForm.get('reports')?.valueChanges.subscribe(reports => { console.log('reports', reports) });
    //this.tableForm.disable();
  }

  cargarZona() {
    this.zonaService.obtenerTodo(this.selectedVno.id).subscribe((resp) => {
      this.arrayZona = [];
      let datos: any[] = [];
      resp.forEach((item: any) => {
        let region = {
          rowKey: item.RowKey,
          precio: item.Precio_Mensual,
          mesInicial: item.Comienzo_Mes.replaceAll('>', ''),
          mesFinal: item.Final_Mes,
          minLima: item.Take_Up_Minimo_Lima.replaceAll('%', ''),
          minProvincia: item.Take_Up_Minimo_Provincia.replaceAll('%', ''),
        };
        datos.push(region);
        let dExtraZona = {
          descripcion: item.Descripcion,
          partitionKey: item.PartitionKey,
          rango: item.Rango,
          rowKey: item.RowKey,
        };
        this.arrayZona.push(dExtraZona);
        //this.dataSourceZona.push(region);
      });

      this.dataSourceZona = new MatTableDataSource(datos);
      this.setUsersFormZona();

      //this.tableFormZona.get('reportsZona')?.valueChanges.subscribe(reports => { console.log('reportsZona', reports) });
      this.estadoGuardar = true;
      this.tableFormZona.disable();
    });
  }

  cargarRegion() {
    this.regionService.obtenerTodo(this.selectedVno.id).subscribe((resp) => {
      this.arrayRegion = [];
      this.dataSource = [];
      resp.forEach((item: any) => {
        let region = {
          rowKey: item.RowKey,
          precio: item.Precio_Mensual,
          mesInicial: item.Comienzo_Mes.replaceAll('>', ''),
          mesFinal: item.Final_Mes,
          minLima: item.Take_Up_Minimo_Lima.replaceAll('%', ''),
          minProvincia: item.Take_Up_Minimo_Provincia.replaceAll('%', ''),
        };
        this.dataSource.push(region);
        let dExtraRegion = {
          rowKey: item.RowKey,
          partitionKey: item.PartitionKey,
          descripcion: item.Descripcion,
          rango: item.Rango,
        };
        this.arrayRegion.push(dExtraRegion);
      });
      this.setUsersForm();
      //this.tableForm.get('reports')?.valueChanges.subscribe(reports => { console.log('reports', reports) });
      this.estadoGuardar = true;
      this.tableForm.disable();
      // this.cerrarLoading();
    });
  }

  enviarRegion() {
    const regiones: any[] = [];
    const userCtrl = this.tableForm.get('reports') as FormArray;
    userCtrl.value.forEach((user: any) => {
      let obj = this.arrayRegion.find((item) => item.rowKey == user.rowKey);
      let region = {
        PartitionKey: obj.partitionKey,
        RowKey: user.rowKey,
        Descripcion: obj.descripcion,
        Precio_Mensual: user.precio,
        Comienzo_Mes: '>' + user.mesInicial,
        Final_Mes: user.mesFinal,
        Take_Up_Minimo_Lima: user.minLima + '%',
        Take_Up_Minimo_Provincia: user.minProvincia + '%',
        Rango: obj.rango,
      };
      regiones.push(region);
    });
    if (regiones.length > 0) {
      this.mostrarLoading();
      this.regionService.actualizar(regiones).subscribe(
        (resp) => {
          this.cargarRegion();
          this.cerrarLoading();
          this.mensajeConfirmacion();
        },
        (error) => {
          this.cerrarLoading();
          this.mensajeError();
        }
      );
    }
  }

  enviarZona() {
    const zonas: any[] = [];
    const userCtrl = this.tableFormZona.get('reportsZona') as FormArray;

    userCtrl.value.forEach((user: any) => {
      let obj = this.arrayZona.find((item) => item.rowKey == user.rowKey);
      let region = {
        RowKey: user.rowKey,
        PartitionKey: obj.partitionKey,
        Descripcion: obj.descripcion,
        Precio_Mensual: user.precio,
        Comienzo_Mes: '>' + user.mesInicial,
        Final_Mes: user.mesFinal,
        Take_Up_Minimo_Lima: user.minLima + '%',
        Take_Up_Minimo_Provincia: user.minProvincia + '%',
        Rango: obj.rango,
      };
      zonas.push(region);
    });
    if (zonas.length > 0) {
      this.mostrarLoading();
      this.zonaService.actualizar(zonas).subscribe(
        (resp) => {
          this.cargarZona();
          this.cerrarLoading();
          this.mensajeConfirmacion();
        },
        (error) => {
          this.cerrarLoading();
          this.mensajeError();
        }
      );
    }
  }

  mensajeConfirmacion() {
    Swal.fire({
      icon: 'success',
      title: 'Confirmación!',
      text: 'Se actualizaron correctamente los registros.',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  mensajeError() {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Hubo un error.',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  mostrarLoading() {
    Swal.fire({
      title: '',
      html: '',
      timerProgressBar: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      width: 'auto',
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  cerrarLoading() {
    Swal.close();
  }

  // UIP UIC SECTION
  onUIPCFileChange(e: any, type: 'UIP' | 'UIC') {
    
    if (e != null && e.target != null) {
      const el: HTMLInputElement = e.target;
      const file = el.files![0];
      if (file) {
        const validFilename = type === 'UIC' ? 'UIC_USUARIO.csv' : 'UIP_USUARIO.csv';
        if (validFilename !== file.name) {
          Swal.fire({
            icon: 'error',
            title: 'Archivo incorrecto!',
            text: 'Por favor subir el archivo con el nombre ' + validFilename,
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          //limpiar este evt
          this.clearFileInput(type);
          return;
        }
        this.uploadData[type].valid = true;
        //this.onFileChange.emit(file);
        //this.uploadFile(file);
        // consolse.log(file);
      }
    }
  }


  clearFileInput(type: 'UIP' | 'UIC') {
    this.uploadData[type].valid = false;
    this.uploadData[type].state = 'none';
    this.uploadData[type].progress = 0;
    if (type === 'UIC' && this.inputUICFile)
      this.inputUICFile.nativeElement.value = '';
    else if (type === 'UIP' && this.inputUIPFile)
      this.inputUIPFile.nativeElement.value = '';
  }

  async uploadFileAzure(type: 'UIP' | 'UIC') {
    // const file: File = event.target.files[0]; // Obtener el archivo
    const file = type === 'UIP'
    ? this.inputUIPFile.nativeElement.files![0] 
    : this.inputUICFile.nativeElement.files![0];
    if (file) {
      const filename = type === 'UIC' ? 'UIC_USUARIO.csv' : 'UIP_USUARIO.csv';
      const tokenURl = environment.urlBase.subirUICPToken;
      this.uploadData[type].state = 'loading';
      // Solicitar el SAS token desde el backend o Azure Function
      const sasToken = await this.http.get<string>(
        tokenURl,
        {responseType: 'text' as any}
      ).toPromise();
      //const sasToken = 'sv=2021-04-10&st=2024-10-14T16%3A19%3A06Z&se=2024-10-14T17%3A24%3A06Z&sr=c&sp=rw&sig=kggoH%2BQf09fVLqwNPNr0uYhloFf3uXzVF0cJDUqLD4s%3D';
      
      // const params = new HttpParams({fromString: sasToken});

      // Conectar al Blob Storage
      // 2 https://staceu2etlbackd04.blob.core.windows.net/?${sasToken}
      const blobServiceClient = new BlobServiceClient(
        `${environment.urlBase.subirUICPBlob}/?${sasToken}`
      );

      // Seleccionar el contenedor y crear una referencia para el blob
      const containerClient = blobServiceClient.getContainerClient('input');
      const blobClient = containerClient.getBlockBlobClient(`REPORT_${type}_USER/${filename}`);

      // Subir el archivo
      try {
        
        await blobClient.uploadData(file, {
          blobHTTPHeaders: { blobContentType: file.type }
        });
        console.log('Archivo subido exitosamente!');
        this.uploadData[type].state = 'done';
        this.uploadData[type].valid = false;
        if (type === 'UIC') this.inputUICFile.nativeElement.value = '';
        else this.inputUIPFile.nativeElement.value = '';
  
        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: 'Archivo subido correctamente',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.clearFileInput(type);
      } catch(err: any) {
        console.log(err, typeof err)
          let message = 'Tubimos un error al subir el archivo!';
          this.uploadData[type].state = 'error';
          if (err.error && err.error.message) {
            message = err.error.message;this.uploadData[type].state = 'error';
          } 
          Swal.fire({
            icon: 'error',
            title: 'Error al subir el archivo!',
            text: message,
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          this.clearFileInput(type);
      }
    }
  }
}
