import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeMainComponent } from './pages/home-main/home-main.component';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ReportsModule } from '../reports/reports.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    HomeMainComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports:[HomeMainComponent]
})
export class HomeModule { }
