import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CargaArchivoService {
  pathAdd: string;
  ruta: string;
  constructor(private http: HttpClient) {
    this.ruta = `${environment.urlBase.cargaArchivo}`;
    this.pathAdd = `${this.ruta}addPrice`;
  }

  agregar(registros: any, vno: any) {
    return this.http.post<any>(this.pathAdd + `?vno=${vno}`, registros);
  }
}
