import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  pathObtenerTodo: string;
  pathAgregar: string;
  pathActualizar: string;
  pathEliminar: string;
  ruta: string;
  constructor(private http: HttpClient) {
    this.ruta = `${environment.urlBase.mantenimientoRegion}`;
    this.pathObtenerTodo = `${this.ruta}getRegion`;
    this.pathAgregar = `${this.ruta}addRegion`;
    this.pathActualizar = `${this.ruta}updateRegion`;
    this.pathEliminar = `${this.ruta}deleteRegion`;
  }

  obtenerTodo(vno: string) {
    return this.http.get<any>(this.pathObtenerTodo + `?vno=${vno}`);
  }

  agregar(regiones: any) {
    return this.http.post<any>(this.pathAgregar, regiones);
  }

  actualizar(region: any) {
    return this.http.put<any>(this.pathActualizar, region);
  }

  eliminar(id: number) {
    const ruta = `${this.pathEliminar}/${id}`;
    return this.http.delete<any>(ruta);
  }
}
