import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.scss']
})


export class HomeMainComponent implements OnInit {

    constructor(private router: Router,
      private authService: MsalService){
 
       }

  
  ngOnInit(): void {


    this.authService.instance.handleRedirectPromise().then( res => {
      if (res != null && res.account != null) {
        this.authService.instance.setActiveAccount(res.account)
      }
    })
  }



isLoggedIn(): boolean {
  return this.authService.instance.getActiveAccount() != null
}

login() {
    
  //this.router.navigateByUrl('/home/reports');
  //this.authService.loginRedirect();
  this.authService.loginPopup()
    .subscribe((response: AuthenticationResult) => {
      this.authService.instance.setActiveAccount(response.account);
      this.router.navigateByUrl('/home/reports');
    });

}

logout() {
  this.authService.logout()
}

}
