import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  rutaUIC: string;
  rutaUIP: string;
  constructor(private http: HttpClient) {
    this.rutaUIC = ``;
    this.rutaUIP = ``;
  }

  public uploadUipUic(file: File, type: 'UIP' | 'UIC'): Observable<UploadResponse> {
    const filename = type === 'UIC' ? 'UIC_USUARIO.csv' : 'UIP_USUARIO.csv';
    const path = type === 'UIC' ? this.rutaUIC : this.rutaUIP;
    // create a new multipart-form for every file
    const headers: HttpHeaders = new HttpHeaders({
      filename: filename
  });
    const formData: FormData = new FormData();
    formData.append('archivoscv', file, file.name);
    // create a http-post request and pass the form
    // tell it to report the upload progress
    const req = new HttpRequest(
      'POST', path, formData,
      {
        headers, reportProgress: true, responseType: 'text'
      },
    );
    // create a new progress-subject for every file
    const progress = new Subject<UploadResponse>();

    // send the http-request and subscribe for progress-updates
    this.http.request(req).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.UploadProgress) {

          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / (event.total ?? 1));
  
          // pass the percentage into the progress-stream
          progress.next({percent: percentDone, data: null});
        } else if (event instanceof HttpResponse) {
          progress.next({percent: 100, data: event.body ?? {success: 'ok'}});
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
        }
      },
      error: (err) => {
        progress.error(err);
      }
    });
    // this.http.request(req).subscribe(event => {
      
    // });
    return progress;
  }

  
}

export interface UploadResponse {
  percent: number;
  data: any;
}
